
<template>
  <div>
    <v-card>
      <v-card-subtitle>Lista </v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="filtro.valor"
              label="numero documento"
              required
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <SelectAlmacen
              v-model="filtro.almacen"
              :add="false"
              :dense="true"
              :outlined="true"
              :clearable="true"
              :filtro="{ id_puesto: store.state.puestoSelect.id_puesto }"
            />
          </v-col>

          <v-col cols="12" md="3">
            <BuscarEmpleado @Getdatos="GetdatosEmpleado" :label="`Empleado`" />
          </v-col>
          <v-col cols="12" md="3">
            <SelectEstado
              v-model="filtro.estado"
              :dense="true"
              :outlined="true"
              :add="false"
              :padre="11"
              :clearable="true"
            />
          </v-col>
          <v-col cols="12" md="3">
            <DatePicker
              :clearable="true"
              v-model="filtro.fecha_ajuste"
              :dense="true"
              :outlined="true"
              :label="'Fecha Factura'"
            />
          </v-col>
          <v-col cols="12" md="3">
            <DatePicker
              :clearable="true"
              v-model="filtro.fecha_creacion"
              :dense="true"
              :outlined="true"
              :label="'Fecha Creacion'"
            />
          </v-col>
          <v-col cols="12" class="text-right">
            <v-btn small @click="cargar()" :loading="cargandoDatos" class="primary"> Buscar </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-progress-linear height="1" v-if="cargandoDatos" indeterminate></v-progress-linear>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center">ID</th>
                <th class="text-left">Numero Documento</th>
                <th class="text-left">Fechas</th>
                <th class="text-left">Puesto</th>
                <th class="text-left">Empleado</th>
                <th class="text-left">Estado</th>
                <th class="text-left">Accion</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in Lista" :key="index">
                <td>{{ item.id }}</td>
                <td>
                  <small>{{ item.numero_documento }}</small>
                  <br />
                  <v-chip x-small color="info">{{ item.tipo_conteo_json.descripcion }}</v-chip>
                </td>
                <td>
                  <small>Creacion {{ funciones.formatoFecha(item.fecha, 3) }}</small>
                  <br />
                  <small>Conteo {{ funciones.formatoFecha(item.fecha_conteo, 1) }}</small>
                </td>

                <td>
                  <small>{{ item.puesto_json.nombre }}</small>
                </td>
                <td>
                  <small>{{ item.empleado_json.nombre }} {{ item.empleado_json.apellido }}</small>
                </td>
                <td>
                  <v-chip x-small :color="colorEstatus(item.estado)">{{ item.estado_json.descripcion }}</v-chip>
                </td>

                <td>

                  <v-tooltip bottom v-if=" item.estado == 85">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn small icon v-bind="attrs" v-on="on" @click="generarAjustes(item.id)" >
                        <v-icon color="success">{{ icons.mdiCheckAll }}</v-icon>
                      </v-btn>
                    </template>
                    <span>Generar ajuste</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.id_tipo_conteo == 3 && item.estado == 84">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn small icon v-bind="attrs" v-on="on" @click="$refs.refInputEl.click()">
                        <v-icon color="success">{{ icons.mdiUpload }}</v-icon>
                      </v-btn>
                    </template>
                    <span>Importar</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.id_tipo_conteo == 3 && item.estado == 84">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn small icon v-bind="attrs" v-on="on" @click="exportExcelConteoTotal(item.id)">
                        <v-icon color="success">{{ icons.mdiDownload }}</v-icon>
                      </v-btn>
                    </template>
                    <span>Exportar</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn small icon v-bind="attrs" v-on="on" @click="GetVer(item)">
                        <v-icon color="primary">{{ icons.mdiEye }}</v-icon>
                      </v-btn>
                    </template>
                    <span>Ver</span>
                  </v-tooltip>
                  <BtnPdfConteo  :id="item.id" :tipoBtn="2"  />
                  <v-tooltip bottom v-if=" item.estado != 86 && item.estado != 87">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn small :disabled="item.estado == 37" icon v-bind="attrs" v-on="on" @click="GetAnular(item)">
                        <v-icon color="error">{{ icons.mdiDeleteOutline }}</v-icon>
                      </v-btn>
                    </template>
                    <span>Anular</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
            <tbody></tbody>
          </template>
        </v-simple-table>
        <div class="text-right">
          <span class="pt-4 mr-2">Total Registros: {{ TotalRow }}</span>
          <br />
          <v-pagination :disabled="cargandoDatos" v-model="Pagina" :length="Paginas" :total-visible="7"></v-pagination>
        </div>
      </v-card-text>

      <input @click="$refs.refInputEl.value=null;" ref="refInputEl" @change="ChangeImg" type="file" accept=".xlsx" :hidden="true" />
    </v-card>

    <v-dialog v-model="dialogLoading" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Cargando por favor espera..
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogMensaje" persistent width="500">
      <v-card>
        <v-card-text v-if="Mensaje.mensaje == 'ERROR AL VALIDAR'">
          <h3 class="text-center"><span class="error--text">Error de validacion</span></h3>
          <v-simple-table fixed-header height="300px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Producto</th>
                  <th class="text-left">Almacen</th>
                  <th class="text-left">Mensaje</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in Mensaje.datos.validaciones" :key="index">
                  <td>{{ item.producto }}</td>
                  <td>{{ item.puesto }}</td>
                  <td>{{ item.validacion }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-text v-else-if="Mensaje.mensaje == 'GUARDADO CON EXITO'" class="text-center">
          <v-icon size="100" color="success" >{{ icons.mdiCheck }}</v-icon>
          <h3 class="text-center"><span  >GUARDADO CON EXITO</span></h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="blue darken-1" text @click="dialogMensaje = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ModalViewConteo  @GetEditar="GetEditar" @GetAnular="GetAnular" @GetValidar="GetValidar" ref="ModalViewConteoRef"></ModalViewConteo>
 
  </div>
</template>
  
<script>
import AjusteServices from '@/api/servicios/inventario/AjusteServices'
import { onBeforeMount, ref, context, watch } from '@vue/composition-api'
import SelectAlmacen from '@/views/sistema/inventario/maestro/almacen/componentes/Select.vue'
import BuscarEmpleado from '@/views/sistema/administracion/archivo/empleado/components/BuscarEmpleado.vue'
import SelectEstado from '@/views/sistema/administracion/archivo/estado/componentes/Select.vue'
import DatePicker from '@/components/DatePicker.vue'
import BtnPdfConteo from './BtnPdfConteo.vue'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiStoreEdit,
  mdiPencilOutline,
  mdiAccountCancel,
  mdiAccountEdit,
  mdiAccountCheck,
  mdiCheck,
  mdiCancel,
  mdiStoreEditOutline,
  mdiPencil,
  mdiCached,
  mdiEye,
  mdiFileExcel,
  mdiUpload,
  mdiDownload,
  mdiCheckBoxOutline,
   mdiCheckAll
} from '@mdi/js'
import config from '@/api/config'
import store from '@/store'
import funciones from '@/funciones/funciones'
import ModalViewConteo from './ModalViewConteo.vue'
import Vue from 'vue'
export default {
  components: {
    SelectAlmacen,
    BuscarEmpleado,
    SelectEstado,
    DatePicker,
    ModalViewConteo,
    BtnPdfConteo
  },
  watch: {
    '$store.state.puestoSelect.id_puesto': function (news, olds) {
      if (news != olds) {
        this.cargarInicio()
      }
    },
  },
  setup(props, context) {
    const search = ref('')
    const Lista = ref([])
    const ListaLoad = ref(false)
    const cargandoDatos = ref(false)
    const Pagina = ref(1)
    const Limit = ref(5)
    const Paginas = ref(1)
    const TotalRow = ref(1)
    const ModalViewConteoRef = ref(null)
    const dialogLoading = ref(false)
    const dialogMensaje = ref(false)
    const refInputEl = ref(false)
    const filtroInit = {
      id_empleado: null,
      id_almacen: null,
      estado: null,
      id_puesto: null,
      fecha_ajuste: null,
      fecha_creacion: null,
      valor: '',
    }
    const anulando = ref(false)
    const colorEstatus = item => {
      switch (item) {
        case 84:
          return 'info'
          break
        case 85:
          return 'warning'
          break
        case 86:
          return 'success'
          break
        case 87:
          return 'error'
          break
        default:
          return 'default'
          break
      }
    }

    const ChangeImg = e => {
    
   

      const dato = e.target.files[0]
      importarExcel(dato)
    }
    const Mensaje = ref({})
    const importarExcel = async _dato => {
      const form = new FormData()
      try {
        
        dialogLoading.value = true
        console.log('_dato', _dato)
        Mensaje.value = {}

        
        form.append('file', _dato, 'excel.xlsx')
        //SUBIR IMAGEN
      } catch (error) {
        dialogLoading.value = false

      }
      
      await AjusteServices.subirConteo(form)
        .then(response => {
          console.log(response)
          dialogMensaje.value = true
          Mensaje.value = response.data
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          console.log('Termino')
          dialogLoading.value = false
          cargar()
        })
    }

    const exportExcelConteoTotal = id_conteo => {
      window.open(
        `${config.api_master}inventario/ajuste/exportarExcelConteoTotal?${funciones.recorrerObjeto({
          id: id_conteo,
          token: store.state.user.token,
        })}`,
      )
    }

    const GetdatosProveedor = datos => {
      if (datos) {
        filtro.value.id_proveedor = datos.id
      } else {
        filtro.value.id_proveedor = null
      }
    }

    const GetAnular = item => {
      if (item.estado != 35) {
        anulando.value = true
        Vue.swal({
          icon: 'warning',
          html: '<h3>¿Seguro que quiere anular el conteo? </h3>' + `<p>${item.numero_documento ?? item.id}</p>`,
          showCancelButton: true,
          confirmButtonText: 'Si, Seguro',
          showLoaderOnConfirm: true,
          cancelButtonText: 'No',
          preConfirm: () => {
            return AjusteServices.conteoAnular({ id: item.id, id_empleado: store.state.user.id_empleado })
              .then(response => {
                if (response.data.mensaje == 'ANULADA CON EXITO') {
                  Vue.swal({
                    icon: 'success',
                    title: `Exito`,
                    text: response.data.mensaje,
                  })
                  cargar()
                } else {
                  Vue.swal({
                    icon: 'error',
                    title: `Oops...`,
                    text: `${response.data.mensaje}`,
                  })
                }
                return true
              })
              .catch(error => {
                Vue.swal({
                  icon: 'error',
                  title: `Oops...`,
                  text: `hubo un error: ${error}`,
                })
                return false
              })
          },
          allowOutsideClick: () => !Swal.isLoading(),
        }).then(result => {
          anulando.value = false
        })
      }
    }
    const GetdatosEmpleado = datos => {
      if (datos) {
        filtro.value.id_empleado = datos.id
      } else {
        filtro.value.id_empleado = null
      }
    }
    const filtro = ref(JSON.parse(JSON.stringify(filtroInit)))
    const GetEditar = item => {
      console.log(item)
      context.emit('GetEditar', { ...item })
    }
    onBeforeMount(() => {
      cargar()
    })
    watch(Pagina, () => {
      cargar()
    })
    const GetVer = item => {
      ModalViewConteoRef.value.abrir(item.id)
    }




    const cargarInicio = () => {
      Pagina.value = 1
      Limit.value = 5
      cargar()
    }
    const generarAjustes = (_id) => {
      try {
        dialogLoading.value = true
        AjusteServices.conteoGenerarAjustes({
          id:_id
        })
          .then(response => {
            console.log(response)
            if (response.data.mensaje == 'GUARDADO CON EXITO') {
              alert(response.data.mensaje)
              cargar()
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            dialogLoading.value = false
          })
      } catch (error) {
        dialogLoading.value = false
      }
    }
    
    const cargar = () => {
      try {
        cargandoDatos.value = true
        AjusteServices.conteoListarPaginado({
          ...filtro.value,
          limit: Limit.value,
          pagina: Pagina.value - 1,
          id_puesto: store.state.puestoSelect.id_puesto,
        })
          .then(response => {
            console.log(response)
            if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
              Lista.value = response.data.datos.datos
              Paginas.value = response.data.datos.paginado.paginas
              TotalRow.value = response.data.datos.paginado.registros
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            cargandoDatos.value = false
          })
      } catch (error) {
        cargandoDatos.value = false
      }
    }

    return {
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAccountCancel,
        mdiAccountEdit,
        mdiAccountCheck,
        mdiCheck,
        mdiCancel,
        mdiStoreEdit,
        mdiStoreEditOutline,
        mdiPencil,
        mdiCached,
        mdiEye,
        mdiFileExcel,

        mdiUpload,
        mdiDownload,
        mdiCheckBoxOutline,
        mdiCheckAll
      },
      search,
      Lista,
      ListaLoad,
      GetEditar,
      cargar,
      config,
      cargandoDatos,
      filtro,
      store,
      funciones,
      colorEstatus,
      Pagina,
      Limit,
      Paginas,
      TotalRow,
      cargarInicio,
      GetdatosEmpleado,
      GetdatosProveedor,
      ModalViewConteoRef,
      GetVer,
      GetAnular,
      anulando,
      exportExcelConteoTotal,
      ChangeImg,
      dialogLoading,
      dialogMensaje,
      Mensaje,
      refInputEl,
      generarAjustes
    }
  },
}
</script>
  
<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
  