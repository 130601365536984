<template>
  <v-card flat class="pa-3 mt-2">
    <v-card-subtitle> Conteo </v-card-subtitle>
    <v-card-text v-if="datos.id">
      <v-form ref="form" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col cols="12">
                {{ datos.id_tipo_conteo }}
                <v-item-group v-model="datos.id_tipo_conteo" mandatory>
                  <v-row>
                    <v-col>
                      <v-item v-slot="{ active }">
                        <v-card
                          disabled="true"
                          :color="active ? 'primary' : ''"
                          class="d-flex align-center"
                          dark
                          height="70"
                          @click="ClickTipoConteo(0)"
                        >
                          <v-scroll-y-transition>
                            <div class="white--text flex-grow-1 text-center">
                              Conteo Express <br />
                              <small
                                >Conteo segun movimientos del dia <br />
                                proximamente
                              </small>
                            </div>
                          </v-scroll-y-transition>
                        </v-card>
                      </v-item>
                    </v-col>
                    <v-col>
                      <v-item v-slot="{ active }">
                        <v-card
                          disabled="true"
                          :color="active ? 'primary' : ''"
                          class="d-flex align-center"
                          dark
                          height="70"
                          @click="ClickTipoConteo(1)"
                        >
                          <v-scroll-y-transition>
                            <div class="white--text flex-grow-1 text-center">
                              Conteo Parcial<br />
                              <small
                                >Conteo parcial de productos
                                <br />
                                proximamente
                              </small>
                            </div>
                          </v-scroll-y-transition>
                        </v-card>
                      </v-item>
                    </v-col>
                    <v-col>
                      <v-item v-slot="{ active }">
                        <v-card
                          :color="active ? 'primary' : ''"
                          class="d-flex align-center"
                          dark
                          height="70"
                          @click="ClickTipoConteo(2)"
                        >
                          <v-scroll-y-transition>
                            <div class="white--text flex-grow-1 text-center">
                              Conteo Total<br />
                              <small>Conteo total de productos</small>
                            </div>
                          </v-scroll-y-transition>
                        </v-card>
                      </v-item>
                    </v-col>
                  </v-row>
                </v-item-group>
              </v-col>
              <v-col cols="12" class="text-center">
                <v-row>
                  <v-col cols="12" class="text-left">
                    <span v-if="datos.id_tipo_conteo == 2">
                      <p><strong>Informacion:</strong></p>
                      <span>
                        Para crear un conteo total primero debes iniciar el conteo, se generara un archivo excel con
                        toda la data necesaria para relaizar el conteo, culminado el conteo fisico se debe subir el
                        archivo con los cambios para poder finalizar el conteo del sistema</span
                      >
                    </span>
                    <span v-else-if="datos.id_tipo_conteo == 0">
                      <p><strong>Informacion:</strong></p>
                      <span>
                        El conteo expres esta diseñado para relizar conteos segun los movimientos realizado en el dia
                        activo
                      </span>
                    </span>
                    <span v-else-if="datos.id_tipo_conteo == 1">
                      <p><strong>Informacion:</strong></p>
                      <span>
                        El conteo parcial esta diseñado para una cantidad limitada de productos donde podras ir
                        agregando producto por producto que necesito realizar el conteo
                      </span>
                    </span>
                  </v-col>
                  <v-col cols="12" sm="6" v-if="datos.id_tipo_conteo == 0">
                    <v-text-field v-model="datos.hora" outlined dense type="time" label="Hora Corte"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" v-if="datos.id_tipo_conteo == 0">
                    <SelectAlmacen
                      :dense="true"
                      :outlined="true"
                      :add="false"
                      v-model="datos.id_almacen"
                      :filtro="{ id_puesto: store.state.puestoSelect.id_puesto, id_tipo_almacen: 1 }"
                      :rules="reglas.requerido"
                    />
                  </v-col>
                  <v-col cols="12" v-if="datos.id_tipo_conteo == 0 || datos.id_tipo_conteo == 1">
                    <v-textarea
                      label="Observacion"
                      value=""
                      rows="4"
                      dense
                      outlined
                      :rules="reglas.requerido"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" v-if="datos.id_tipo_conteo == 0">
                    <v-btn
                      :loading="cargarProductosConteoCargando"
                      v-if="datos.id_tipo_conteo == 0"
                      @click="cargarProductosConteo()"
                      class="secondary"
                      :disabled="!datos.hora_corte && !datos.id_almacen"
                      >Buscar productos</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" v-if="datos.id_tipo_conteo == 0 || datos.id_tipo_conteo == 1">
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th width="10%" class="text-left">ID</th>
                        <th class="text-left">Producto</th>
                        <th class="text-left">Almacen</th>
                        <th width="30%" class="text-left">Cantidad</th>
                        <th width="10%" class="text-left text-center">Cantidad Real</th>
                        <th width="5%" class="text-left text-center"></th>
                        <th width="10%" class="text-left text-center">Final</th>
                        <th width="10%" class="text-left text-center">Diferencia</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in datos.productos.filter(it => !it.omitir)"
                        :key="index"
                        :class="focusSelect == 'fieldId_' + index ? 'grey2' : ''"
                      >
                        <td>{{ item.id_producto }}</td>
                        <td>
                          {{ item.nombre }}
                          <br />
                          <small class="info--text">{{ !item.ind_peso_conteo ? '' : 'Calculado en peso' }} </small>
                        </td>

                        <td>
                          {{ item.descripcion_almacen }}
                        </td>
                        <td>
                          <v-text-field
                            class="inputPrice"
                            :prepend-icon="
                              item.conteo_peso_total > 0
                                ? !item.ind_peso_conteo
                                  ? icons.mdiCheckboxBlankOutline
                                  : icons.mdiCheckboxMarked
                                : ''
                            "
                            :id="'fieldId_' + index"
                            :ref="'fieldId_' + index"
                            v-on:keydown="enterInput"
                            dense
                            outlined
                            hide-details="auto"
                            :suffix="item.ind_peso_conteo ? 'g' : item.unidad"
                            v-model="item.conteo"
                            @click:prepend="item.ind_peso_conteo = !item.ind_peso_conteo"
                            @focus="focusSelect = 'fieldId_' + index"
                            :autofocus="index == 0"
                            type="number"
                            :rules="reglas.requerido_mayor_cero"
                          ></v-text-field>
                          <small v-if="item.ind_peso_conteo"
                            ><strong>Peso Total: </strong>{{ item.conteo_peso_total }} g</small
                          >
                          <small v-if="item.ind_peso_conteo">
                            / <strong>Peso Vacio:</strong> {{ item.conteo_peso_vacio }} g</small
                          >
                          <small v-if="item.ind_peso_conteo">
                            / <strong>Total Unidades:</strong> {{ item.conteo_unidades_totales }}
                            {{ item.unidad }}</small
                          >
                        </td>
                        <td class="text-center">
                          {{
                            !item.ind_peso_conteo
                              ? Number(item.conteo).toFixed(2)
                              : funciones.truncarDecimal(
                                  (item.conteo_unidades_totales / (item.conteo_peso_total - item.conteo_peso_vacio)) *
                                    (item.conteo - item.conteo_peso_vacio),
                                )
                          }}
                          {{ item.unidad }}
                        </td>
                        <td class="text-center"><v-btn x-small @click="item.omitir = true">omitir</v-btn></td>
                        <td class="text-center">
                          {{ item.final }}
                        </td>
                        <td class="text-center">
                          {{
                            Number(
                              !item.ind_peso_conteo
                                ? Number(item.conteo).toFixed(2)
                                : (
                                    (item.conteo_unidades_totales / (item.conteo_peso_total - item.conteo_peso_vacio)) *
                                    (item.conteo - item.conteo_peso_vacio)
                                  ).toFixed(0),
                            ) - item.final
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12">
            <v-divider class="my-2"></v-divider>
            <div class="text-right">
              <v-btn color="secondary" class="me-3" @click="mostratOmitidos()" v-if="omitidos()" small>
                Mostrar Omitidos
              </v-btn>
              <v-btn color="secondary" class="me-3" @click="limpiar()" small v-if="datos.id_tipo_conteo != 2">
                Limpiar
              </v-btn>
              <v-btn color="primary" class="me-3" small @click="Guardar()">
                {{ datos.id_tipo_conteo == 2 ? 'Iniciar' : 'Guardar' }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <KeyBoardEvents v-on:keydown="keyboardEvent"></KeyBoardEvents>
    <ModalConfirmacion ref="ModalConfirmacionRef" />
  </v-card>
</template>
  <script>
import { onActivated, onBeforeMount, onMounted, ref, watch } from '@vue/composition-api'
import store from '@/store'
import goTo from 'vuetify/lib/services/goto'
import DatePicker from '@/components/DatePicker.vue'
import BuscarProducto from '@/views/sistema/inventario/producto/componentes/BuscarProductoExistencia.vue'
import SelectEstado from '@/views/sistema/administracion/archivo/estado/componentes/Select.vue'
import funciones from '@/funciones/funciones'
import TooltipInfo from '@/components/TooltipInfo.vue'
import {
  mdiInformation,
  mdiDelete,
  mdiPencil,
  mdiCheckboxBlankOutline,
  mdiCheckboxMarked,
  mdiPrinter,
  mdiDownload,
  mdiUpload,
} from '@mdi/js'
import FabricacionServices from '@/api/servicios/inventario/FabricacionServices'
import SelectAlmacen from '@/views/sistema/inventario/maestro/almacen/componentes/Select.vue'

import moment from 'moment'
import AjusteServices from '@/api/servicios/inventario/AjusteServices'
import MaestroService from '@/api/servicios/MaestroService'
import ProductosService from '@/api/servicios/ProductosService'
import KeyBoardEvents from '@/components/KeyBoardEvents.vue'
import ModalConfirmacion from '@/components/ModalConfirmacion.vue'
export default {
  components: { SelectAlmacen, KeyBoardEvents, ModalConfirmacion },
  watch: {
    '$store.state.puestoSelect.id_puesto': function () {
      this.limpiar()
    },
    datos: function () {
      this.actualizarStore()
    },

    'datos.productos': function () {
      this.actualizarStore()
    },
  },

  methods: {
    ClickTipoConteo(_new) {
      console.log('_new', _new)
      const _old = this.datos.id_tipo_conteo

      if (this.datos.productos.length == 0) {
        this.datos.id_tipo_conteo = _new
        this.limpiar()
      } else {
        this.preguntarLimpiar(_new)
      }
    },
    enterInput(valor) {
      const inputSplit = valor.target.id.split('_')
      if (valor.key == 'Enter' || valor.key == 'ArrowDown') {
        const inputNewId = inputSplit[0] + '_' + (Number(inputSplit[1]) + 1)
        const inputNew = this.$refs[inputNewId]

        if (inputNew) {
          console.log(inputNew[0])
          inputNew[0].focus()
        }
      } else if (valor.key == 'ArrowUp') {
        const inputNewId = inputSplit[0] + '_' + (Number(inputSplit[1]) - 1)
        const inputNew = this.$refs[inputNewId]

        if (inputNew) {
          inputNew[0].focus()
        }
      } else {
        this.actualizarStore()
      }
    },
    keyboardEvent(e) {
      let tecla = e.which

      if (tecla == 38) {
        e.preventDefault()
        e.stopPropagation()
        return false
      }
      if (tecla == 40) {
        console.log(this.focusSelect)
        e.preventDefault()
        e.stopPropagation()

        return false
      }

      // Evitar el comportamiento por defecto del nevagador:

      // Mostrar el resultado de la combinación de las teclas:
    },
  },
  setup(props, context) {
    const focusSelect = ref(null)
    const id_tipo_conteo = ref(2)
    const guardando = ref(false)

    const ModalConfirmacionRef = ref()

    const preguntarLimpiar = _new => {
      ModalConfirmacionRef.value.abrir({
        icon: mdiInformation,
        subTitulo: '<h3>Se perdera lo que has realizado, ¿seguro que quieres cabiar de modo de conteo?</h3>',
        btnConfirmar: {
          esperarRespuesta: true,
          texto: 'Si',
          color: 'primary',
          funcionDatos: {},
          funcion: async _dat => {
            datos.value.id_tipo_conteo = _new
            limpiar()
          },
        },
        btnCancelar: {
          texto: 'No',
          color: 'secondary',
          funcion: () => {},
        },
      })
    }

    onMounted(() => {
      if (store.state.documentosIniciados.conteo.productos.length > 0) {
        ModalConfirmacionRef.value.abrir({
          icon: mdiInformation,
          subTitulo: '<h3>Hay un documento que no se cerro normalmente, ¿quiere continuarlo?</h3>',
          btnConfirmar: {
            esperarRespuesta: true,
            texto: 'Si',
            color: 'primary',
            funcionDatos: {},
            funcion: async _dat => {
              datos.value = store.state.documentosIniciados.conteo
            },
          },
          btnCancelar: {
            texto: 'No',
            color: 'secondary',
            funcion: () => {
              limpiar()
            },
          },
        })
      } else {
        limpiar()
      }
    })
    const reglas = {
      requerido: [v => !!v || 'Es requerido'],
      requerido_mayor_cero: [v => (!!v && v >= 0) || 'Debe ser mayo  iguala 0'],
    }
    const datosInit = {
      id: -1,
      fecha: moment().format('DD-MM-YYYY'),
      hora: moment().format('HH:mm:00'),
      productos: [],
      id_almacen: null,
      id_tipo_conteo: 2,
    }
    const datos = ref({})
    const cargarProductosConteoCargando = ref(false)
    const cargarProductosConteo = () => {
      datos.value.productos = []
      cargarProductosConteoCargando.value = true
      AjusteServices.consultarConteoExpress({
        id_puesto: store.state.puestoSelect.id_puesto,
        hora_corte: datos.value.hora,
        id_almacen: datos.value.id_almacen,
      })
        .then(response => {
          if (response.data && response.data.length > 0) {
            datos.value.productos = response.data
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          cargarProductosConteoCargando.value = false
        })
    }
    const cargarProductos = () => {
      datos.value.productos = []
      cargarProductosConteoCargando.value = true
      ProductosService.rptInventarioActual({
        id_puesto: store.state.puestoSelect.id_puesto,
        existencia: 0,
      })
        .then(response => {
          // = response.data
          console.log(response.data.datos)
          response.data.datos.forEach(item => {
            datos.value.productos.push({
              nombre: item.producto.nombre,
              id_producto: item.producto.id,
              id_puesto: item.id_puesto,
              id_almacen: item.id_almacen,
              descripcion_almacen: item.almacen.descripcion,
              inicial: 0,
              actual: item.existencia,
              final: item.existencia,
              ventas: 0,
              translados: 0,
              otros: 0,
              unidad: item.unidad_medida.unidad,
              ind_peso_conteo: false,
              conteo_peso_total: null,
              conteo_peso_vacio: null,
              conteo_unidades_totales: null,
              omitir: false,
              conteo: '',
            })
          })
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          cargarProductosConteoCargando.value = false
        })
    }
    const omitidos = () => {
      const xx = datos.value.productos.filter(item => item.omitir == true)

      return xx.length > 0
    }

    const mostratOmitidos = () => {
      datos.value.productos.forEach(item => (item.omitir = false))
    }

    const actualizarStore = () => {
      store.commit('setDocumentosIniciadosConteo', datos.value)
    }

    const limpiar = () => {
      datos.value = JSON.parse(JSON.stringify({ ...datosInit, id_tipo_conteo: datos.value.id_tipo_conteo }))

      store.commit('setDocumentosIniciadosConteo', {})
    }
    const validarForm = () => {
      let val = form.value?.validate()

      return val
    }

    const ActualizarLista = item => {
      console.log(item)
      context.emit('GetActualizarLista', { ...item })
    }
    const Guardar = () => {
      guardando.value = true
      if (validarForm()) {
        try {
          AjusteServices.conteoActualizar({
            ...datos.value,
            id_tipo_conteo: datos.value.id_tipo_conteo + 1,
            id_puesto: store.state.puestoSelect.id_puesto,
            id_empleado: store.state.user.id_empleado,
          })
            .then(response => {
              console.log(response)
              if (response.data.mensaje == 'GUARDADO CON EXITO') {
                store.commit('setAlert', {
                  message: response.data.mensaje,
                  type: 'success',
                })
                ActualizarLista(response.data.datos)
                limpiar()
              } else {
                store.commit('setAlert', {
                  message: `${response.data.mensaje}`,
                  type: 'warning',
                })
              }
            })
            .catch(error => {
              console.log(error)
              store.commit('setAlert', {
                message: error,
                type: 'error',
                error: {
                        ...error, 
                        response: error?.response?.data
                    },
                funcion: 'guardar',
              })
            })
            .finally(() => {
              guardando.value = false
            })
        } catch (error) {
          store.commit('setAlert', {
            message: error,
            type: 'error',
            error: error,
            funcion: 'guardar',
          })
        }
      } else {
        store.commit('setAlert', {
          message: 'Verifique que todos los datos estén completos',
          type: 'warning',
        })
      }
    }

    const form = ref(null)
    return {
      limpiar,
      datos,
      cargarProductosConteo,
      cargarProductos,
      cargarProductosConteoCargando,

      mostratOmitidos,
      omitidos,
      focusSelect,
      icons: {
        mdiCheckboxBlankOutline,
        mdiCheckboxMarked,
        mdiDownload,
        mdiUpload,
      },
      moment,
      store,
      reglas,
      form,
      Guardar,
      funciones,
      actualizarStore,
      ModalConfirmacionRef,
      preguntarLimpiar,
    }
  },
}
</script>
<style scoped>
.inputPrice >>> input[type='number'] {
  -moz-appearance: textfield;
}
.inputPrice >>> input::-webkit-outer-spin-button,
.inputPrice >>> input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
</style>