<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" scrollable max-width="900px">
      <ViewConteo
        :VerButton="VerButton"
        @GetCerrarModal="GetCerrarModal"
        @GetAnular="GetAnular"
        @GetEditar="GetEditar"
        @GetPdf="GetPdf"
        @GetFinalizar="GetFinalizar"
        :id="id"
        :modal="dialog"
      ></ViewConteo>
    </v-dialog>
  </v-row>
</template>
<script>
import { ref } from '@vue/composition-api'
import ViewConteo from './ViewConteo.vue' 
export default {
  components: {
    ViewConteo,
  },
  props: {
    VerButton: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const dialog = ref(false)
    const ViewCompraRef = ref(null)
    const id = ref(null)
    const abrir = idin => {
      
      id.value = idin
      dialog.value = true
    }
    const GetCerrarModal = () => {
      dialog.value = false
    }
    const GetAnular = item => {
      context.emit('GetAnular', item)
      dialog.value = false
    }
    const GetEditar = item => {
      context.emit('GetEditar', item)
      dialog.value = false
    }

    const GetValidar = item => {
      context.emit('GetValidar', item)
      dialog.value = false
    }
    const GetPdf = item => {
      context.emit('GetPdf', item)
    }
    const GetFinalizar = item => {
      context.emit('GetFinalizar', item)
      dialog.value = false
    }
   
    return {
      dialog,
      abrir,
      id,
      GetCerrarModal,
      GetAnular,
      GetEditar,
      GetPdf,
      GetFinalizar,
      GetValidar, 
    }
  },
}
</script>
