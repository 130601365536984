<template>
  <v-card>
    <v-card-title>
      <span>Conteo</span>
      <v-spacer></v-spacer>
      <v-btn v-if="modal == true" icon @click="cerrarModal()">
        <v-icon>{{ icons.mdiClose }}</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text v-if="cargando" class="text-center">
      <h4>Buscando el conteo...</h4>
    </v-card-text>
    <v-card-text v-if="!cargando && datos">
      <v-row>
        <v-col lg="8" md="8" sm="12" cols="12">
          <CabeceraEmpresa></CabeceraEmpresa>
        </v-col>
        <v-col lg="4" md="4" sm="12" cols="12">
          <h2 class="pt-0 pb-2 text-center"># {{ datos.numero_documento }}</h2>
          <div class="text-center">
            Fecha Conteo <br />
            <h2 class="pt-0 pb-2 text-center">
              <small> {{ FuncionesGenerales.formatoFecha(datos.fecha_conteo, 1) }} </small>
            </h2>
          </div>
        </v-col>
      </v-row>
      <v-divider></v-divider>

      <v-row class="mt-4 mb-2">
        <v-col md="8" cols="12">
          <span class="m-0 p-0"> <strong>Tipo Conteo: </strong> {{ datos.tipo_conteo_json.descripcion }} </span>
          <br />
          <span class="m-0 p-0"> <strong>Estado: </strong> {{ datos.estado_json.descripcion }} </span>
          <br />
          <span class="m-0 p-0"> <strong>Area: </strong> {{ datos.puesto_json.nombre }} </span>
          <br />
        </v-col>
        <v-col md="4" cols="12">
          <span class="m-0 p-0">
            <strong>Empleado Creador: </strong> {{ datos.empleado_json.nombre + ' ' + datos.empleado_json.apellido }}
          </span>
          <br />
          <span class="my-0 py-0">
            <strong>Fecha creacion: </strong> {{ FuncionesGenerales.formatoFecha(datos.fecha, 1) }}
          </span>
          <br />
          <span class="my-0 py-0">
            <strong>Hora creacion: </strong> {{ FuncionesGenerales.formatoFecha(datos.fecha, 2) }}
          </span>
          <br />
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row>
        <v-col cols="12">
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Id</th>
                  <th class="text-left">Producto</th>
                  <th class="text-left">almacen</th>
                  <th class="text-right">Existencia</th>
                  <th class="text-right">Conteo</th>
                  <th class="text-right">Diferencias</th>
                  <th class="text-left">Motivo</th>
                  <th class="text-left">Observacion</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in datos.detalles" :key="i">
                  <td class="text-left">{{ item.producto.id }}</td>
                  <td class="text-left">{{ item.producto.nombre }}</td>
                  <td class="text-right">{{ item.almacen.descripcion }}</td>
                  <td class="text-right">{{ item.final }}</td>
                  <td class="text-right">{{ item.conteo }}</td>
                  <td class="text-right">{{ item.diferencias }}</td>
                  <td class="text-left">
                    <small>{{ item.motivo.descripcion }}</small>
                  </td>
                  <td class="text-left">
                    <small>{{ item.observacion }}</small>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <br />
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col cols="7">
              <strong class="m-0 p-0"> Desccripcion: </strong> <br />
              <span>{{ datos.descripcion }} </span>
            </v-col>
            <v-col cols="5">
              <v-simple-table dense>
                <tbody>
                  <tr>
                    <td colspan="2"><strong>Totales</strong></td>
                  </tr>
                  <tr>
                    <td>Total Items</td>
                    <td class="text-right">
                      {{ datos.detalles.length }}
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-row>
        <v-col cols="12" class="text-center mt-5">
          <BtnPdfConteo :id="id" :tipoBtn="1"></BtnPdfConteo>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>
<script>
import CabeceraEmpresa from '@/components/CabeceraEmpresa.vue'
import FuncionesGenerales from '@/funciones/funciones'
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import AjusteServices from '@/api/servicios/inventario/AjusteServices'
import config from '@/api/config'
import store from '@/store'
import { acciones } from '@/modulos'
import { mdiClose, mdiPrinter, mdiPencil, mdiCheck, mdiBlockHelper, mdiFilePdfBox, mdiAccountCheck } from '@mdi/js'
import BtnPdfConteo from './BtnPdfConteo.vue'
export default {
  components: {
    CabeceraEmpresa,
    BtnPdfConteo,
  },
  props: {
    id: Number,
    modal: Boolean,
    VerButton: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const cargando = ref(false)
    const datos = ref({})
    const verReal = ref(false)
    onBeforeMount(() => {
      cargar()
    })
    watch(props, () => {
      cargar()
    })
    const validarAcciones = accion => {
      const valor = store.state.user.accion_modulo.includes(accion)

      return valor
    }
    const cerrarModal = () => {
      context.emit('GetCerrarModal')
    }
    const cargar = () => {
      cargando.value = true
      AjusteServices.conteoListarDetalle({ id: props.id })
        .then(response => {
          if ((response.data.mensaje = 'BUSQUEDA_EXITOSA')) {
            if (response.data.datos.datos != null && response.data.datos.datos.length > 0) {
              datos.value = response.data.datos.datos[0]
            } else {
              store.commit('setAlert', {
                message: 'No hay resultado en la busqueda',
                type: 'warning',
              })
            }
          } else {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'warning',
            })
          }
        })
        .catch(err => {
          store.commit('setAlert', {
            message: err,
            type: 'error',
            error: {
                        ...err, 
                        response: error?.response?.data
                    },
            funcion: 'cargar',
          })
        })
        .finally(() => {
          cargando.value = false
        })
    }
    const GetValidar = item => {
      context.emit('GetValidar', item)
    }
    const GetAnular = item => {
      context.emit('GetAnular', item)
    }
    const GetEditar = item => {
      context.emit('GetEditar', item)
    }
    const GetPdf = item => {
      context.emit('GetPdf', item)
    }
    const GetFinalizar = item => {
      context.emit('GetFinalizar', item)
    }

    return {
      datos,
      FuncionesGenerales,
      cerrarModal,
      cargar,
      icons: {
        mdiClose,
        mdiPrinter,
        mdiPencil,
        mdiCheck,
        mdiBlockHelper,
        mdiFilePdfBox,
        mdiAccountCheck,
      },
      GetAnular,
      GetEditar,
      GetPdf,
      GetFinalizar,
      validarAcciones,
      GetValidar,
      acciones,
      verReal,
      config,
    }
  },
}
</script>