<template>
  <span>
    <v-btn v-if="tipoBtn == 1" color="info" small @click="CargarDatos()" :loading="cargandoDatos" :disabled="!id">
      <v-icon left small>
        {{ icons.mdiFilePdfBox }}
      </v-icon>
      PDF
    </v-btn>

    <v-tooltip bottom v-else>
      <template v-slot:activator="{ on, attrs }">
        <a class="pr-1" v-bind="attrs" v-on="on" @click="CargarDatos()">
          <v-icon color="primary">{{ icons.mdiFilePdfBox }}</v-icon>
        </a>
      </template>
      <span>PDF</span>
    </v-tooltip>
    <Pdf ref="pdfRef"></Pdf>
    
  </span>
</template>
<script>
import AjusteServices from '@/api/servicios/inventario/AjusteServices'
import { mdiFilePdfBox } from '@mdi/js'
import { ref } from '@vue/composition-api'
import FuncionesGenerales from '@/funciones/funciones'
import Pdf from '@/components/PdfFormatoLibreDocumentos.vue'
export default {
  props: {
    id: Number,
    tipoBtn: {
      type: Number,
      default: 1,
    },
  },
  components: {
    Pdf,
  },
  setup(props) {
    const dialogTipoPrint = ref(false)
    const cargandoDatos = ref(false)
    const pdfRef = ref(null)
    let datoInit = {}
    let verReal = false

    

    
    const CargarDatos = () => {
      AjusteServices.conteoListarDetalle({ id: props.id })
        .then(response => {
          console.log(response)
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            datoInit = response.data.datos.datos[0] 
              GetPdf() 
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          cargandoDatos.value = false
        })
    }

    const GetPdf = _valor => {
      verReal = _valor

      dialogTipoPrint.value = false
      const cuerpoTablaDetalles = []
 
      datoInit.detalles.forEach(item => {
        cuerpoTablaDetalles.push([
          item.producto.id.toString(),
          item.producto.nombre,
          item.almacen.descripcion,
          FuncionesGenerales.formatoNumeric(item.final),
          FuncionesGenerales.formatoNumeric(item.conteo),
          FuncionesGenerales.formatoNumeric(item.diferencias),
          item.motivo.descripcion?item.motivo.descripcion:'',  
        ])
      })
     

      const datosInit = {
        cabecera1: { visible: true },
        nombreDocumento: `Conteo: ${datoInit.numero_documento}`,
        etiqueta: {
          visible: false, 
        },
        numeroDocumento: {
          texto: 'Conteo: ',
          valor: datoInit.id.toString(),
        },
        numeroControl: {
          texto: 'Doc Nro',
          valor: datoInit.numero_documento,
        },
        numeroFecha: {
          texto: 'Fecha Conteo',
          valor:  FuncionesGenerales.formatoFecha(datoInit.fecha_conteo, 1) ,
        },
        sub1: {
          linea1: [
            {
              texto: 'Tipo Conteo:',
              valor: datoInit.tipo_conteo_json.descripcion,
            },
            
            { texto: 'Estado:', valor: datoInit.estado_json.descripcion },
            {
              texto: 'Area:',
              valor: datoInit.puesto_json.nombre,
            },
             
          ],
          linea2: [
            {
              texto: 'Empleado:',
              valor: datoInit.empleado_json.nombre + ' ' + datoInit.empleado_json.apellido,
            },
            { texto: 'Fecha Creacion:', valor: FuncionesGenerales.formatoFecha(datoInit.fecha, 1) },
            {
              texto: 'Hora Creacion:',
              valor: FuncionesGenerales.formatoFecha(datoInit.fecha, 2)
                .replace(/\u00a0/g, '')
                .replace(/\u202f/g, ''),
            },
          
          ],
          
        },
        sub2: {
          linea1: [],
          linea2: [],
        },

        pie: {
          nota: {
            texto: 'Descripcion:',
            valor: datoInit.descripcion,
          },
          totales: [
            {
              texto: 'Total Items:',
              valor: datoInit.detalles.length.toFixed(2),
            } 
          ],
        },

        detalles: {
          cabecera: [
            { texto: 'Id', posicion: '45', alineacion: 'left' },
            {
              texto: 'Producto',
              posicion: '80',
              alineacion: 'left',
              justificar: { maxWidth: 130, align: 'justify', maximoItems: 33 },
            },
            { texto: 'Alamcen', posicion: '200', alineacion: 'left' },
            { texto: 'Existencia', posicion: '300', alineacion: 'right' },
            { texto: 'Conteo', posicion: '360', alineacion: 'right' },
            { texto: 'Diferencias', posicion: '440', alineacion: 'right' }, 
            { texto: 'Motivo', posicion: '555', alineacion: 'right' },
          ],
          cuerpo: cuerpoTablaDetalles,
        },
      }

     

      if (datoInit.estado == 87) {
       

        datosInit.etiqueta = {
          visible: true,
          valor: 'ANULADO',
          color: '#FF0000'
        }
        
      } else  if (datoInit.estado == 86) {
       

       datosInit.etiqueta = {
         visible: true,
         valor: 'APLICADO',
         color: '#06C200'
       }
       
     } 

      

      console.log('datosInit', datosInit)
      pdfRef.value.generarPDF(datosInit)
    }

    return {
      GetPdf,
      cargandoDatos,
      pdfRef,
      dialogTipoPrint,
      icons: {
        mdiFilePdfBox,
      },
      CargarDatos,
    }
  },
}
</script>